<template>
  <div class="post__container">
    <i
      @click="back"
      class="fa fa-chevron-left post__back"
      aria-hidden="true"
    ></i>
    <div class="post__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    back() {
      this.$router.back();
    },
  },
};
</script>
<style lang="scss">
@import "~@/assets/styles/main.scss";
</style>
