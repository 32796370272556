<template>
  <default-layout>
    <post-layout>
      <h1>{{ news[$i18n.locale][0].title }}</h1>
      <p v-html="news[$i18n.locale][0].detail"></p>
    </post-layout>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/layout/DefaultLayout";
import PostLayout from "@/layout/PostLayout.vue";

export default {
  data() {
    return {
      news: { vi: [], en: [] },
    };
  },
  async created() {
    const res = await this.$axios.get(`/api/post/${this.$route.params.id}`);
    this.news = await Object.assign({}, res.data);
  },
  components: {
    DefaultLayout,
    PostLayout,
  },
};
</script>